<template>
    <div v-if="source != ''">
        <v-overlay
            :value="loading"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <vue-friendly-iframe :src="source" @iframe-load="onIframeLoad()" :style="dynamicStyle" class="iframe-height"></vue-friendly-iframe>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "InvoiceForecast",
    data() {
        return {
            loading: true
        }
    },
    computed: {
        ...mapGetters('user',{
            ZohoReports : 'ZohoReports'
        }),
        dynamicStyle () {
            return '--spacer-height: 155px'
        },
        source () {
            let report = this.ZohoReports.find(r => r['report_key'] == 'invoice_forecast')
            if(report) {
                return report['report_permalink']
            }
            return '';
        }
    },
    methods: {
        onIframeLoad() {
            this.loading = false
        }
    }
}
</script>

<style>
.full-height {
    height: calc(100vh - 77px);
}
.iframe-height iframe {
    border: 0;
    height: calc(100vh - var(--spacer-height));
}
</style>